
import React, { useContext, useEffect, useRef, useState } from 'react'
import 'intersection-observer';
import { navigate } from '../navigate'
import ListContainer from '../Components/listContainer';
import FeaturedCarousel from '../Components/featuredCarosel';
import CardContainer from '../Components/cardConatiner';
import LangCarousel from '../Components/langCarousel';
// import Header from '../Components/header';
import Footer from '../Components/footer';
import Cookies from 'js-cookie';
import OttPartners from '../Components/OttPartners';
import { UserContext } from '../Contexts/userContext';
import { activeElementBorderColor, logEvent, popupModal } from '../utils/helper';
import { apiUrl } from '../site.config';
import PackAlertPopup from '../Components/packAlertPopup';
import Loader from '../Components/loader';
import ContinueContainer from '../Components/continueContainer';
import HalfHome from './halfHomeComponent';

export default function Homepage() {

  let rendered = useRef(false)
  const componentRef = useRef();
  let cur = useRef(0)
  let curList = useRef(0)
  let loggedIn = Cookies.get('logged_in') || false;
  let popupAlert = Cookies.get('popupAlert') || false;
  let clientId = Cookies.get('clientId') || ''
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('Subscribe');
  const [rankingData, setRankingData] = useState([])
  const User = useContext(UserContext)


  let viewAll;
  function homeEvents(flag = false) {
    rendered.current = false
    viewAll = document.querySelectorAll("[home-selectable='true']");
    if (viewAll.length) {
      document.addEventListener("keydown", handlekey)
      if (viewAll[cur.current].id === 'featuredCarousel' && !flag) {
        handleactiveInsideWidget(viewAll[cur.current].id);
      }
    }
  }
  const observerRef = useRef(null);

  useEffect(() => {
    // Callback function to handle mutations
    const handleMutation = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        // Check if nodes were added
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            if (mutation.addedNodes[i].className === 'fixedContainer') {
              // console.log('A div element was externally added:', mutation.addedNodes[i]);
              rendered.current = true
              rendered.current && homeEvents(true);
              // Handle the externally added div element here
            }
          }
        }
      });
    };
    // Create a new observer instance
    const observer = new MutationObserver(handleMutation);

    // Start observing the target node (body) for DOM mutations
    if (!loading) {
      observer.observe(document.body, { childList: true, subtree: true });
    }
    // Store the observer instance in ref for cleanup
    observerRef.current = observer;

    // Clean up on unmount
    return () => {
      // Disconnect the observer when component unmounts
      observer.disconnect();
    };
  }, []);
  const divRef = useRef(null);

  useEffect(() => {
    (() => {
      if (!window.navigator.onLine) {
        popupModal("beacame offline", 3500);
      }
      console.log("routes", JSON.parse(localStorage.getItem('visitedRoutes')))
      if (!JSON.parse(localStorage.getItem('visitedRoutes'))) {
        let newlocalArray = [];
        newlocalArray.push('/')
        localStorage.setItem('visitedRoutes', JSON.stringify(newlocalArray));
      }
      setLoading(true)
      getSubData();
    })();
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);

  useEffect(() => {
    Cookies.remove('routePath');
    Cookies.set("login_type", false);
    clientId && fetchSubscricption();
    // return ()=>{ document.removeEventListener("keydown",handlekey)} 
  }, [])

  const fetchSubscricption = () => {
    fetch(`${apiUrl}/v4.5/mobile/plansubscribe/getusersubscription`, { headers: { client_id: clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (subsData) {
        // This is the JSON from our response
        console.log("subsData", subsData);
        if (subsData?.displayMessage) {
          User.setUser(Object.assign({}, User.user, { 'paragraph1': subsData?.paragraph1, 'paragraph2': subsData?.paragraph2, 'packname': subsData?.packname, 'planName': subsData?.packname }))
          if (popupAlert == 'true') {
            showPopup();
            Cookies.set("popupAlert", false);
            setTimeout(() => {
              hidePopup();
            }, 3500);
          }
        }
        if (subsData?.plans?.length) {
          User.setUser(Object.assign({}, User.user, { 'planName': subsData?.plans[0]?.name }))
          Cookies.set('subscribed', true);
          Cookies.set('subscribeId', subsData?.plans[0]?.subscription_id);
          Cookies.set('productId', subsData?.plans[0]?.product_id);
          Cookies.set('plan_code', subsData?.plans[0]?.plan_code);
          setPlanName(subsData?.plans[0]?.name);
        } else {
          User.setUser(Object.assign({}, User.user, { 'planName': 'Subscribe' }))
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  }

  function showPopup() {
    document.getElementById("overlay").style.display = "block";
  }
  function hidePopup() {
    document.getElementById("overlay").style.display = "none";
  }
  const getSubData = () => {
    fetch(`${apiUrl}/v2.2/widgets?menu=Home`, { headers: { 'client_id': clientId, 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" } })
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        // This is the JSON from our response
        console.log(data);
        if (data?.data && data?.data?.length) {
          setTimeout(() => {
            setLoading(false)
            rendered.current = true
            rendered.current && homeEvents();
          }, 2500)
          setRankingData(data.data)
        }
        else {
          setLoading(false)
          setRankingData([])
        }
      }).catch(function (err) {
        // setLoading(false)
        // There was an error
        console.warn('Something went wrong.', err);
      });
  }

  const handleactiveInsideWidget = (id, k = "", h = 0) => {
    if (k === "up") {
      window.scrollTo({ top: h })
    }
    else {
      window.scrollTo({ top: h - 90 })
    }
    listItemAll = document.querySelectorAll(`[widget-selectable=${id}]`)
    if (viewAll[cur.current].id === 'featuredCarousel' && listItemAll[curList.current]) {
      // console.log("featuredccccccccccc",listItemAll[curList.current])
      listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`
      showSlide(curList.current);
    }
    if (listItemAll.length) {
      listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`
    }

  }
  function showSlide(index) {
    if (curList.current === 0) {
      document.getElementById('prevArrow').style.display = 'none';
      document.getElementById('nextArrow').style.display = 'block'
    } else if (curList.current === listItemAll.length - 1) {
      document.getElementById('prevArrow').style.display = 'block';
      document.getElementById('nextArrow').style.display = 'none'
    }
    else {
      document.getElementById('prevArrow').style.display = 'block'
      document.getElementById('nextArrow').style.display = 'block'

    }


    for (let i = 0; i < listItemAll.length; i++) {
      listItemAll[i].style.display = "none"
    }


    listItemAll[index].style.display = 'block';
  }
  const handlekey = (e) => {
    // if (e.key == '1') {
    //   navigate(e,'/livetv-home')
    // }
    if (e.key === "ArrowUp") {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      if (cur.current === -1) {
        document.querySelector('.home').style.top = "38px";
        return
      }
      if (cur.current === 0) {
        document.querySelector('.home').style.top = "38px";
        let headerNav = document.querySelectorAll('[head-selectable]')[0];
        if (viewAll[cur.current].id === 'featuredCarousel') {
          listItemAll[curList.current].style.removeProperty('border');
        }
        headerNav.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current -= 1;
        // return;
      }



      else if (cur.current % 2 !== 0) {
        viewAll[cur.current].focus();
        viewAll[cur.current].style.removeProperty("color");
        viewAll[cur.current].style.removeProperty("font-weight");
        if (cur.current != 0) cur.current = cur.current - 1
        handleactiveInsideWidget(viewAll[cur.current].id, "up", viewAll[cur.current].offsetTop || 0);
      }
      else {
        if (listItemAll.length) listItemAll[curList.current].style.removeProperty("border");
        curList.current = 0;
        viewAll[cur.current - 1].style.color = '#29F87E';
        viewAll[cur.current - 1].style.fontWeight = '700';
        viewAll[cur.current - 1].scrollIntoView();
        if (cur.current != 0) cur.current = cur.current - 1
        // document.querySelector('.home').style.top="28px";

      }

      if (cur.current === 1 || cur.current == 0) {
        document.querySelector('.home').style.top = "28px";

      }
    }

    if (e.key == "ArrowDown") {
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
      if (cur.current === 1) {
        document.querySelector('.home').style.top = "38px";
      }
      if (cur.current === -1 || cur.current === -2) {
        document.querySelector('.home').style.top = "88px";
        let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
        if (headerNav) {
          headerNav.style.removeProperty("box-shadow");
          headerNav.style.removeProperty("outline");
        };
        cur.current = 0;
        if (viewAll[cur.current].id === 'featuredCarousel') {
          document.getElementsByTagName("body")[0].style.overflow = "hidden"
          window.scrollTo({ top: 0 })
          handleactiveInsideWidget(viewAll[cur.current].id, "down", viewAll[cur.current].offsetTop || 0);
        }

        return
      };
      if (cur.current == viewAll.length - 1) return;
      else if (cur.current % 2 !== 0) {
        viewAll[cur.current].style.removeProperty("color");
        viewAll[cur.current].style.removeProperty("font-weight");
        if (cur.current != viewAll.length - 1) cur.current = cur.current + 1;
        handleactiveInsideWidget(viewAll[cur.current].id, "down", viewAll[cur.current].offsetTop || 0);
      }

      else {
        if (listItemAll.length) listItemAll[curList.current].style.removeProperty("border");
        curList.current = 0;
        if (cur.current != viewAll.length - 1) cur.current = cur.current + 1;
        viewAll[cur.current].style.color = '#29F87E';
        viewAll[cur.current].style.fontWeight = '700';
        window.scrollTo({ top: viewAll[cur.current].offsetTop - 70 })
        // viewAll[cur.current].scrollIntoView(false);
      }
      if (cur.current === -1 || cur.current === -2) {
        document.querySelector('.home').style.top = "88px";
      }

    }
    if (e.key === "ArrowRight") {
      if (cur.current === -2) return;
      if (cur.current === -1) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavSubs.style.removeProperty('box-shadow')
        headerNavSubs.style.removeProperty('outline')
        headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current -= 1;
      }
      else if (viewAll[cur.current].id === 'featuredCarousel') {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != listItemAll.length - 1) curList.current = curList.current + 1;
        showSlide(curList.current);
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
      else {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != listItemAll.length - 1) curList.current = curList.current + 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
    }
    if (e.key == "ArrowLeft") {
      if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
        headerNavProfile.style.removeProperty('box-shadow')
        headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
        cur.current += 1;
      }
      else if (viewAll[cur.current] && viewAll[cur.current].id === 'featuredCarousel') {
        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != 0) curList.current = curList.current - 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
        showSlide(curList.current);
      } else {

        listItemAll[curList.current].style.removeProperty("border");
        if (curList.current != 0) curList.current = curList.current - 1;
        listItemAll[curList.current].style.border = `2px solid ${activeElementBorderColor}`;
      }
    }
    if (e.key == "Enter") {
      if (cur.current == -1) {
        let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
        if (headerNavSubs) {
          navigate(e, `${headerNavSubs}`)
        }
        return;
      }
      else if (cur.current === -2) {
        let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
        if (headerNavProfile) {
          navigate(e, `${headerNavProfile}`)
        }
        return;
      }
      let title = viewAll[cur.current].title
      let widgetName = viewAll[cur.current].getAttribute('widgetname')
      let id = viewAll[cur.current].id
      if (title) {
        logEvent(`viewAll`, "click", `${title}`)
        // navigate('/plans')
        // window.gtag('event', 'button_click', {
        //   'event_category': 'viewAll',
        //   'event_label': 'button_clicked',
        //   'value': 1  // Optional numeric value associated with the event
        // });
        navigate(e, `${title}`)

      }
      else {
        handleOk(listItemAll[curList.current], e);

      }
    }
  }
  let listItemAll;

  const handleOk = (ele, e) => {
    if (viewAll[cur.current].id === 'ottpartners') {
      Cookies.set('providersId', ele.getAttribute('seo_url'))
    }
    let state = {
      content_type: ele.getAttribute('content-type'),
      ottplay_id: ele.getAttribute('ottplay_id'),
      provider: ele.getAttribute('providers'),
      _id: ele.getAttribute('_id') || '',
    }
    let langState = {
      languageId: ele.getAttribute('lang-id'),
    }
    let showstate = {
      seo_url: ele.getAttribute('seo_url')
    }
    let path = ele.getAttribute('to');
    let eventName = viewAll[cur.current] === 'featuredCarousel' ? 'subscriptions_carousel' : `${ele.getAttribute('content-type')}_click`
    logEvent(eventName, `${ele.getAttribute('content-type')}/${ele.getAttribute('name')}`, `${ele.getAttribute('ottplay_id')}/${ele.getAttribute('providers')}`)

    path.includes('/player') ? navigate(e, path, state) : path.includes('/language-detail') ? navigate(e, path, langState) : navigate(e, path, showstate);

  }
  const conditionalRender = (item, id) => {

    switch (item?.template_name) {
      case 'subscription_banner':
        return <FeaturedCarousel item={item} key={id} id={id} uniqueId={'featuredCarousel'} setLoading={setLoading} />
      case 'providers':
        return <OttPartners item={item} key={`home-${id}`} id={id} uniqueId={'ottpartners'} page={"Home"} />
      case 'live_tv':
        return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={false} content_type={item?.content_type} key={id} id={id} uniqueId={'liveTV'} page={"Home"} />
      case 'movies':
        return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'top10Movies'} page={"Home"} />
      case 'shows':
        return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'top10Shows'} page={"Home"} />
      case 'recommended_movies_for_you':
        return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'recommended_movies_for_you'} page={"Home"} />
      case 'recommended_shows_for_you':
        return <ListContainer item={item} key={`nav-${id}`} id={id} uniqueId={'recommended_shows_for_you'} page={"Home"} />
      case 'new_release':
        return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={true} content_type={'movie'} key={`nav-${id}`} id={id} uniqueId={'newRelease'} page={"Home"} />
      case 'trending_in_sports':
        return <CardContainer item={item} componentRef={componentRef} loading={loading} longWidget={true} content_type={item?.content_type} key={`nav-${id}`} id={id} uniqueId={item.section} page={"Home"} />
      case 'top_languages':
        return <LangCarousel item={item} key={`home-${id}`} uniqueId={'top_languages'} />
      case 'continue_watching':
        return loggedIn ? <ContinueContainer item={item} divRef={divRef} longWidget={true} content_type={'movie_show'} key={`nav-${id}`} id={id} uniqueId={'k'} page={"Home"} /> : null
      default:
        break;
    }
  }
  return (
    <>
      {/* <Header /> */}
      <div className='home' id="home">
        <div className={loading ? `firstContainer` : null}>
          {
            !!rankingData?.length && rankingData.slice(0, 4)?.map((item, id) => { return conditionalRender(item, id) }
            )
          }
        </div>
        {loading ? <div className="loaderPlans"><Loader /></div> : null}
          <HalfHome divref={divRef}/>        
      </div>

      <PackAlertPopup />
      {/* <Footer /> */}
      <style jsx="true">{`
     .home{
      background-color: #270048;
      height:calc(100vh-30px);
      width: 100%;
      position:absolute;
      top:28px;
      overflow-x : hidden;
      overflow-y:scroll;
      padding-top:5px;
      padding-bottom:35px;
      }
      .cardContainer a:focus {
        border:2px solid yellow;
      }
      .loaderPlans {
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: -30px;
        background-color: #270048;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .firstContainer {
        width: 100vw;
        min-height: 100vh;
      }      
      .space{
        margin-bottom:8px;
      }
   
     `}</style>
    </>
  )
}