import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js/dist/hls.js";
import { navigate, navigateBack } from "../navigate";
import { routes } from "../routes";
import Cookies from "js-cookie";
import { fetchData } from "../Api/client";

import { root } from "../index";
import Loader from "../Components/loader";
import { apiUrl, subsApi } from "../site.config";
import { logEvent, readookie, setCookies } from "../utils/helper";
import UserProvider from "../Contexts/userContext";
import App from "../App";
var HLS = new Hls();
export default function VideoPlayer() {
  const [playbackURL, setPlaybackURL] = useState("");
  let action;
  let label;
  let displayIcon = useRef(false)
  let seekfIcon = useRef(false)
  let seekbIcon = useRef(false)
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  let logged_in = Cookies.get("logged_in") || false;
  let subscribed = Cookies.get("subscribed") || false;
  let client_id = Cookies.get("clientId") || "";
  let plan_code = Cookies.get("plan_code") || "";
  const [isPlaying, setIsPlaying] = useState(false)
  let auth = Cookies.get("auth") || "";
  let totalVideoDurationRef = useRef(0);
  // let isplaying = useRef(false)
  let videoProgress = 0;
  var progressBar = document.getElementById('progressBar');

  const [user_id, setUserId] = useState(window?.history?.state?._id || "")
  const [contentTitle, setContentTitle] = useState(window?.history?.state?.name || "")
  const [showData, setShowData] = useState(window?.history?.state?.showData || "")
  const [content_type, setContent_type] = useState(
    window?.history?.state?.content_type || ""
  );
  const [ottplay_id, setOttplay_id] = useState(
    window?.history?.state?.ottplay_id || ""
  );
  const [provider, setProvider] = useState(
    window?.history?.state?.provider || ""
  );
  const [subscriptionType, setSubscriptionType] = useState(
    window?.history?.state?.subscriptionType || ""
  );
  const [episodeData, seteEisodeData] = useState([]);
  const [pause, setPause] = useState(false);
  const rendered = useRef(false);
  const playpause = useRef(true);
  const indexref = useRef(0);
  useEffect(() => {
    document.getElementById('progressBar').style.display = 'none';
    rendered.current = true;
    let apiUrl = `${subsApi}api/partner-service/v4.2/videoplayer?content_type=${content_type}&ottplay_id=${ottplay_id}&provider=${provider}`;
    // if (true) {
    if (logged_in === 'true' && subscribed === 'true') {
      setLoading(true);
      fetchVideo(apiUrl);
    } else if (logged_in === 'true' && subscribed === 'false') {
      if (subscriptionType == "free") {
        setLoading(true);
        fetchVideo(apiUrl);
      } else {
        logEvent('subscribe_to_watch', 'subscribe_click', provider)
        root.render(<UserProvider><App /></UserProvider>);
        navigate({}, '/plans', {}, true)
      }
    } else {
      root.render(<UserProvider><App /></UserProvider>);
      navigate({}, '/login', {}, true)
    }
    if (content_type == 'show') {
      fetchSeasonData();
      findIndex();
    }
    action = content_type == 'show' ? `${content_type}/${contentTitle}/${showData}` : `${content_type}/${contentTitle}`;
    label = `${ottplay_id}/${provider}`;

    return () => window.removeEventListener("keydown", handleKeyEvents);
  }, []);

  useEffect(() => {
    if (logged_in === 'true' && subscribed === 'true')
      // if (true)
      playStream("Playing player Video", playbackURL);
    else if (logged_in === 'true' && subscribed === 'false') {
      if (subscriptionType == "free") {
        playStream("Playing player Video", playbackURL);
      } else {
        root.render(<UserProvider><App /></UserProvider>);
        navigate({}, '/plans', {}, true)
      }
    } else {
      root.render(<UserProvider><App /></UserProvider>);
      navigate({}, '/login', {}, true)
    }
  }, [playbackURL]);

  useEffect(() => {
    let video = document.getElementById("video")
    if (video) {
      video.addEventListener('ended', handleVideoEnded);
    }
    return () => {
      if (video) {
        video.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [playbackURL])


  const handleVideoEnded = () => {
    if (content_type == 'show') {
      setOttplayId();
    } else {
      HLS.stopLoad();
      HLS.detachMedia();
      HLS.destroy();
      logEvent(`watch_duration`, action, label)
      navigateBack();
    }

  };
  const setOttplayId = () => {
    setLoading(true)
    removeVideoTag();
    // indexref.current = episodeData.findIndex(x => x.ottplay_id === ottplay_id);
    if (indexref.current < episodeData.length - 1) {
      indexref.current = indexref.current + 1
      let apiUrl = `${subsApi}api/partner-service/v4.2/videoplayer?content_type=${content_type}&ottplay_id=${episodeData[indexref.current].ottplay_id}&provider=${provider}`;
      fetchVideo(apiUrl);
      playStream("Playing player Video", playbackURL);
    }
    else {
      HLS.stopLoad();
      HLS.detachMedia();
      HLS.destroy();
      logEvent(`watch_duration`, action, label)
      navigateBack();
    }
    // setOttplay_id(episodeData[index + 1].ottplay_id)
  }
  const fetchSeasonData = () => {
    const headers = { 'authorization': "bearer 34B7FBD24B97D7A4CF717D414F2A8" }
    let apiurl = `${apiUrl}/v4.6/episode/seo/url?seoUrl=${window?.history?.state?.seoUrl}&limit=10&page=1&sortBy=${window?.history?.state?.episodeNumber > 50 ? "desc" : "asc"
      }&seasonNumber=${window?.history?.state?.seasonNumber}`;
    fetch(apiurl, { headers: headers })
      .then(function (response) {
        // The API call was successful!
        return response.json();
      }).then(function (data) {
        // This is the JSON from our response
        // console.log("seasonDat2", data);
        if (Object.keys(data).length) {
          seteEisodeData(data?.episodes);
          indexref.current = data?.episodes.findIndex(x => x.ottplay_id === ottplay_id);
        }
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
  };
  const findIndex = () => {
    // indexref.current = episodeData.findIndex(x => x.ottplay_id === ottplay_id);

  }

  const handleKeyEvents = (event) => {
    const keyName = event.key;
    if (keyName == "ArrowDown") {
      if (displayIcon.current) return;
      if (seekbIcon.current) return;
      let videoControl = document.getElementById("video");
      videoControl.currentTime = videoControl.currentTime + 30;
      document.getElementById('seekForward').style.display = 'block';
      seekfIcon.current = true
      setTimeout(() => {
        seekfIcon.current = false
        document.getElementById('seekForward').style.display = 'none';

      }, 2000);
    }
    if (keyName === "ArrowUp") {
      if (displayIcon.current) return;
      if (seekfIcon.current) return;
      let videoControl = document.getElementById("video");
      videoControl.currentTime = videoControl.currentTime - 30;
      document.getElementById('seekreverse').style.display = 'block';
      seekbIcon.current = true
      setTimeout(() => {
        seekbIcon.current = false
        document.getElementById('seekreverse').style.display = 'none';

      }, 2000);
    }
    if (keyName == "ArrowRight") {
      navigator.volumeManager && navigator.volumeManager.requestUp();
    }
    if (keyName == "3") {
      navigator.volumeManager && navigator.volumeManager.requestUp();
    }
    if (keyName == "ArrowLeft") {
      navigator.volumeManager && navigator.volumeManager.requestDown();
    }
    if (keyName == "1") {
      navigator.volumeManager && navigator.volumeManager.requestDown();
    }
    if (keyName == "Enter") {
      let videoControl = document.getElementById("video");
      if (videoControl) {
        if (playpause.current == true) {
          videoControl.pause();
          setIsPlaying(true)
          // setShowIcon(true)
          displayIcon.current = true
          document.getElementById('controlsbutton').style.display = 'block';
          setTimeout(() => {
            // setShowIcon(false)
            displayIcon.current = false
            document.getElementById('controlsbutton').style.display = 'none';

          }, 2000);
          playpause.current = false;
        } else {
          videoControl.play();
          setIsPlaying(false)
          // setShowIcon(true)
          displayIcon.current = true
          document.getElementById('controlsbutton').style.display = 'block';
          setTimeout(() => {
            // setShowIcon(false)
            displayIcon.current = false
            document.getElementById('controlsbutton').style.display = 'none';

          }, 2000);
          playpause.current = true;
          logEvent(`title_resume`, action, label)
        }
      }
    }
    if (keyName === "Backspace") {

      event.preventDefault();
      (() => {
        try {

          continueWatching();
        }
        catch (err) {

        }
      })()
      HLS.stopLoad();
      HLS.detachMedia();
      HLS.destroy();
      let videoControl = document.getElementById("video");
      logEvent(`watch_duration`, action, label)
      navigateBack();
    }
  };

  const continueWatching = () => {
    let videoControl = document.getElementById("video");
    videoProgress = videoControl ? videoControl.currentTime : 0;
    let data = {
      ottplay_id: ottplay_id,
      provider: provider,
      progress: videoProgress,
      is_watching: false,
      is_partner_content: true,
      continue_watching: true,
      content_type: content_type,
      duration: content_type === 'live_tv' ? videoProgress : totalVideoDurationRef.current,
      show_id: user_id,
    };
    if (content_type != 'show') {
      delete data['show_id']
    }
    let headers = {
      apiversion: "1",
      auth: auth,
      authorization: "Bearer F421D63D166CA343454DD833B599C",
      client_id: client_id,
      "content-type": "application/json;charset=UTF-8",
      device_id: "aeab2074-90b3-4387-9939-5caf68db8d8e",
      devicetype: "jiophone",
      origin: "https://www.ottplay.com",
      pc_type: "0",
      plan_code: plan_code,

      "sec-fetch-mode": "cors",
      unique_id: "aeab2074-90b3-4387-9939-5caf68db8d8e",
      user_id: client_id,
    };
    let apiURL = `${subsApi}api/v2.1/web/mediaprogress/progress`;
    let response = fetch(apiURL, {
      headers: headers,
      method: "PUT",
      body: JSON.stringify(data),
    }).then((r) => r.json());
  };
  if (rendered.current == false) {
    window.addEventListener("keydown", handleKeyEvents);
  }

  const fetchVideo = (apiUrl) => {
    let headers = {
      provider: "5f456c2aff9ccd034434e6fd",
      pc_type: false,
      auth: 'MFSILB7TAR3VKHDIHMWVYC7SWMAG2VB775MOEBFDQOVH7KPFGKKBGAULNJMSCRFUIF2KKUPPARQZUWXWJPHXF5RJQP6MHZYOKQ7NSGY=',
      device_type: "ios",
      apiversion: 1,
      plan_code: "ott_all",
      platform: "jiophone ",
      client_id: client_id,
      device_code: "jiophone",
      device_id: "5C1C3C68-DB4F-4C05-BF7F-9E673216CEAB",
      Cookie: "ident-stat=location=AS,date=1694498613,accepted=0",
    };
    let data = fetch(apiUrl, { method: "GET", headers }).then((r) =>
      r.json()
    ).then(function (data) {
      console.log("videoplayer", data);
      if (data?.playback) {

        setPlaybackURL(data.playback[0].playback_url);
        if (data?.playback[0]?.cookies) {
          setCookies(data?.playback[0]?.cookies);
        }
      } else if (!data?.success) {
        document.getElementById('progressBar').style.display = 'none';
        setLoading(false);
        setMessage(data?.msg);
        logEvent(`title_title_playback_failed`, action, label)
        showPopup();
      }
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });
  };
  const playStream = (label, url) => {
    let providerarray = ['609b70d48d929d001e66953a','653b64a7014cb3001c8c1cbb','65b0d8dab8c329001e0e97ba']
    if (playbackURL) {
      let videoControl = document.getElementById("video");
      if (!videoControl) {
        addVideoTag();
      }
      url = url.includes('fps_hls') ? url.replace('fps_hls', 'hls') : url;
      // url = provider == '609b70d48d929d001e66953a' ? url.replace('https://streaming.ottplay.com', 'https://preprod-streaming.ottplay.com') : url;
      url = providerarray.includes(provider) ? url.replace('https://streaming.ottplay.com', ' https://d28yzxaluo5asr.cloudfront.net') : url;
      play(url);
    } else if (!playbackURL) {
      // showPopup();
    }
  };

  function addVideoTag() {
    const videoEle = document.getElementsByClassName("video");
    const video = document.createElement("video");
    video.id = "video";
    videoEle[0].appendChild(video);
  }

  // Function to show the pop-up
  function showPopup() {
    setTimeout(() => {
      document.getElementById("overlay").style.display = "flex";
    }, 1000);
  }

  // Function to hide the pop-up
  function hidePopup() {
    document.getElementById("overlay").style.display = "none";
  }

  function updateProgressBar() {
    const video = document.getElementById("video");
    if (video) {
      var currentTime = video.currentTime;
      var buffered = video.buffered;

      if (buffered.length > 0) {
        var bufferEnd = buffered.end(0);
        var duration = video.duration;

        if (!isNaN(duration) && duration > 0) {
          var progress = (currentTime / duration) * 100;
          progressBar.value = progress;
        }
      }
    }
  }

  function play(src) {
    try {
      const video = document.getElementById("video");
      // video.controls = true;

      if (Hls.isSupported() && video) {
        const hlsConfig = {
          capLevelToPlayerSize: true,
          startLevel: 0, // Set the initial quality level to the lowest (0 corresponds to the lowest quality)
          // ... other HLS.js configuration options
          "enableWorker": true,
          "lowLatencyMode": true,
          "backBufferLength": 90,
          // debug: true,
          "playlistLoadPolicy": {
            "default": {
              "maxTimeToFirstByteMs": 10000, "maxLoadTimeMs": 20000,
              "timeoutRetry": { "maxNumRetry": 10, "retryDelayMs": 0, "maxRetryDelayMs": 0 },
              "errorRetry": { "maxNumRetry": 10, "retryDelayMs": 1000, "maxRetryDelayMs": 8000 }
            }
          }
        };
        const hlsConfigNama = {
          capLevelToPlayerSize: true,
          startLevel: 0, // Set the initial quality level to the lowest (0 corresponds to the lowest quality)
          // ... other HLS.js configuration options
          "enableWorker": true,
          "lowLatencyMode": true,
          "backBufferLength": 90,
          // debug: true,
          "playlistLoadPolicy": {
            "default": {
              "maxTimeToFirstByteMs": 10000, "maxLoadTimeMs": 20000,
              "timeoutRetry": { "maxNumRetry": 10, "retryDelayMs": 0, "maxRetryDelayMs": 0 },
              "errorRetry": { "maxNumRetry": 10, "retryDelayMs": 1000, "maxRetryDelayMs": 8000 }
            }
          },
        };
        var HLS = provider == '609b70d48d929d001e66953a' ? new Hls(hlsConfigNama) : new Hls(hlsConfig);
        HLS.loadSource(src);
        HLS.attachMedia(video);
        HLS.on(Hls.Events.MANIFEST_LOADED, function (event, data) {
          console.log('Manifest loaded successfully.');
          if (content_type != 'live_tv' && HLS.levels[0].height <= 480) {
            document.getElementById('progressBar').style.display = 'flex';
            logEvent(`title_play`, action, label)
          }
          else {
            logEvent(`Livetv_play`, action, label)
          }
          // setLoading(false);
        });

        HLS.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log('Manifest parsed successfully.');
          setTimeout(() => {
            var tracks = video.textTracks;
            console.log('Manifest parsed successfully along with subtitles.',tracks);
            for (var i = 0; i < tracks.length; i++) {
              tracks[i].mode = 'hidden';  // Disable subtitles
            }
          }, 5000);
          // setLoading(false);
          console.log('levels', HLS.levels);
          if (HLS.levels[0].height <= 480) {
            video.play();
          }
          else {
            logEvent(`title_playback_failed`, action, label)
            setMessage("This content is not supported on your device");
            document.getElementById('progressBar').style.display = 'none';
            showPopup();
            HLS.stopLoad();
            removeVideoTag();
          }
        });
        HLS.on(Hls.Events.BUFFER_APPENDING, function (event, data) {
          updateProgressBar();
        });
        HLS.on(Hls.Events.LEVEL_SWITCHED, (evt, data) => {
          const level = HLS.levels[data.level];
          if (level) {
            setLoading(false);
            document.getElementById('videRes').innerHTML = `${level.width}x${level.height}`
            // console.log(`qualityChange ${level.width}x${level.height}`);
          }
        });

        HLS.on(Hls.Events.LEVEL_LOADED, onLevelLoaded);
        HLS.on(Hls.Events.ERROR, onError);

        function onLevelLoaded(event, data) {
          totalVideoDurationRef.current = data.details.totalduration || 0;
          console.log("video duration", totalVideoDurationRef.current);

        }

        function onError(event, data) {
          console.error("HLS.js error:", event, data);
          if (data.response && (data.response.code == 403 || data.response.code == 504)) {
            console.log("error occured on hls", data.response.code)
            setMessage("The requested title could not be played right now. Please Refresh and try again.");
            document.getElementById('progressBar').style.display = 'none';
            showPopup();
            HLS.stopLoad();
            removeVideoTag();
          }
        }
      }
    } catch (ee) {
      console.error("Exception during video playback:", ee);
    }
  }






  function removeVideoTag() {
    const video = document.getElementById("video");
    video.remove();
  }

  return (
    <>
      <div id="container">
        <div className="centerDiv">
          <div className="video"></div>
          <progress id="progressBar" className="progress" value="0" max="100"></progress>
          <h5 className="videresolution" id="videRes"></h5>
          <div className="controlsIcon" id="controlsbutton">
            <svg
              width="60"
              height="60"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 7H5V17H2V7Z" fill="currentColor" />
              <path d="M6 12L13.0023 7.00003V17L6 12Z" fill="currentColor" />
              <path d="M21.0023 7.00003L14 12L21.0023 17V7.00003Z" fill="currentColor" />
            </svg>
            {/* pause button */}
            {!isPlaying ? <svg
              width="60"
              height="60"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11 7H8V17H11V7Z" fill="currentColor" />
              <path d="M13 17H16V7H13V17Z" fill="currentColor" />
            </svg>
              :
              <svg
                width="60"
                height="60"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 12.3301L9 16.6603L9 8L15 12.3301Z" fill="currentColor" />
              </svg>}
            <svg
              width="60"
              height="60"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21.0023 17H18.0023V7H21.0023V17Z" fill="currentColor" />
              <path d="M17.0023 12L10 17V7L17.0023 12Z" fill="currentColor" />
              <path d="M2 17L9.00232 12L2 7V17Z" fill="currentColor" />
            </svg>
          </div>
          <div className="controlsIconseek" id="seekForward">
            <svg
              width="60"
              height="60"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21.0023 17H18.0023V7H21.0023V17Z" fill="currentColor" />
              <path d="M17.0023 12L10 17V7L17.0023 12Z" fill="currentColor" />
              <path d="M2 17L9.00232 12L2 7V17Z" fill="currentColor" />
            </svg>
          </div>
          <div className="controlsIconseek" id="seekreverse">
            {/* <h6>10 sec</h6> */}
            <svg
              width="60"
              height="60"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 7H5V17H2V7Z" fill="currentColor" />
              <path d="M6 12L13.0023 7.00003V17L6 12Z" fill="currentColor" />
              <path d="M21.0023 7.00003L14 12L21.0023 17V7.00003Z" fill="currentColor" />
            </svg>
          </div>
        </div>
        {loading ? <Loader /> : null}
        <div id="overlay" className="alertPopupWrap">
          <p className="popupPara">{message}</p>
        </div>
      </div>

      <style jsx="true">
        {`
          #container {
          }
          #video {
            height: 100%;
            width: 100%;
          }
          .video {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 9999999;
          }
          .controlsIcon {
            display:none;
            position: absolute;
            z-index: 99999999;
            color: #ffffff;
            top: 98px;
            left: 85px;
          }
          .controlsIconseek {
            display:none;
            position: absolute;
            z-index: 99999999;
            color: #ffffff;
            top: 100px;
            left: 125px;
          }
          .centerDiv {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100vh;
            height: 100vw;
            transform: translate(-50%, -50%) rotate(90deg);
            background: #000;
          }
          .alertPopupWrap {
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 14px 10px;
            background: #ffffff;
            border-radius: 4px;
            text-align: center;
            width: 100%;
            max-width: 204px;
            flex-direction: column;
            z-index: 9999;
          }
          .popupHeading {
            font-size: 19px;
            line-height: 23px;
            font-weight: bold;
            color: #551787;
            margin: 0;
          }
          .popupPara {
            font-size: 14px;
            line-height: 17px;
            font-weight: 500;
            color: #270048;
            margin: 0;
            padding: 2px 0 0px;
          }
          .loaderWrap i {
            position: absolute;
            z-index: 9999;
            top: 120px;
            left:100px;
          }
          .videresolution{
            top: -12px;
            color: aqua;
            position: absolute;
            z-index: 999999;
            left: 10px;
          }
          .progress{
            display:none;
            position: absolute;
            bottom: 15px;
            width: 98%;
            height: 3%;
          }
        `}
      </style>
    </>
  );
}
