import Cookies from "js-cookie";

export const fetchData = (url, configs = {}) => {
  let loggedIn = Cookies.get('logged_in') || false;


  let { headers = {} } = configs || {};
  if (!headers['authorization']) headers['authorization'] = "bearer 34B7FBD24B97D7A4CF717D414F2A8"
  if (loggedIn) {
    headers['auth'] = Cookies.get('auth') || '';
    if (!headers['client_id']) headers['client_id'] = Cookies.get('clientId') || '';
  }
  // let options = {headers,restConfigs}
  try {
    console.log("asbjbkajbkdn")
    fetch(url, configs).then(function (response) {
      // The API call was successful!
      return response.json();
    }).then(function (data) {
      // This is the JSON from our response
      console.log(data);
      return data;
    }).catch(function (err) {
      // There was an error
      console.warn('Something went wrong.', err);
    });

    // console.log("jcbjkcnksnkcm",response)
    // fetch(url,configs).then((r) => {console.log("first,r.",r.json()); return r.json()}).then((data)=>console.log("dataaaaaaa",data));

    // if (response) {
    //   return response
    // }
    // else{
    //   return null ;
    // }
  } catch (err) {
    return null;
  }
};


const { fetch: originalFetch } = window;
window.fetch = (...args) => {
  let [resource, options] = args;
  if (Cookies.get('logged_in') && options && options.headers?.key!='AllOtt') {
    let fetchOptions = Object.assign({},options&&options.headers,{auth:Cookies.get('auth'),client_id: Cookies.get('clientId')})
    options.headers = fetchOptions;    
  }
  // do something before request

  const response = originalFetch(resource, options);
  // do something after response
  return response;
};