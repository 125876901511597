import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/header";
import Footer from "../Components/footer";
import { apiUrl } from "../site.config";
import { navigate } from "../navigate";
import { activeBtnColor } from "../utils/helper";
import Loader from "../Components/loader";
import Cookies from "js-cookie";

const AllLanguage = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState('item_0')
  let licount;
  const cur = useRef(0);
  const currentRef = useRef(false)
  useEffect(() => {
    ( () => {
      Cookies.remove('pageNo');
      Cookies.remove('page');
      setLoading(true)
       getLangData();
      // setTimeout(() => {
      //   currentRef.current = true
      //   currentRef.current && handleActiveBorder();
      // }, 3000);
    })()
    return () => { document.removeEventListener("keydown", handlekey) }
  }, []);
   function getLangData() {
   fetch(
      `${apiUrl}/re-service/v1.3/web/language/personalized-languages?limit=10&pin_it=false&template_name=top_languages&section=widget_browser_by_language&page=1&module_name=Home`
    ).then(function (response) {
      return response.json();
    }).then(function (result) {
      console.log(result);
      if (Array.isArray(result.rank) && result.rank.length) {
        setTimeout(() => {
          setLoading(false)
          currentRef.current = true
          currentRef.current && handleActiveBorder();
        }, 1000);
        setLanguages(result.rank);
      } else {
        setLoading(false)
        setLanguages([]);
      }
    }).catch(function (err) {
      console.warn('Something went wrong.', err);
    });
  }

  const handleActiveBorder = () => {
    currentRef.current = false
    let v = document.getElementById(selectedItem);
    licount = document.querySelectorAll(`[li-selectable='languages']`).length;
    if (v) {
      v.classList.add('activeLanguage')
      document.addEventListener("keydown", handlekey);
    }
  }

  const handlekey = (e) => {
    e.preventDefault();
    let keyName = e.key;
    let id = document.querySelector('.activeLanguage')?.id
    if (id) {
      let eleId = parseInt(document.querySelector('.activeLanguage').id.split('_')[1]);
      switch (keyName) {
        case "ArrowRight":
          if (eleId < licount - 1) {
            handlHorizontal(eleId, eleId + 1)
          }
          break;
        case "ArrowLeft":
          if (eleId > 0) {
            handlHorizontal(eleId, eleId - 1)
          }
          break;
        case "ArrowUp":
          if (eleId > 2) {
            handlHorizontal(eleId, eleId - 3)
          }
          else if (cur.current === 0) {
            document.getElementById(`item_${eleId}`).classList.remove('activeLanguage')
            let headerNav = document.querySelectorAll('[head-selectable]')[0];
            headerNav.style.outline = `2px solid ${activeBtnColor}`
            cur.current -= 1;
          }
          break;
        case "ArrowDown":
          if (eleId < licount - 3) {
            handlHorizontal(eleId, eleId + 3)
          }
          break;
        case "Enter":
          handleOk(id);
          break;

        default:
          break;
      }
    }
    else {
      switch (keyName) {

        case "ArrowRight":
          if (cur.current === -2) return;
          if (cur.current === -1) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavSubs.style.removeProperty('box-shadow')
            headerNavSubs.style.removeProperty('outline')
            headerNavProfile.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current -= 1;
          }
          break;
        case "ArrowLeft":
          if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1];
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0];
            headerNavProfile.style.removeProperty('box-shadow')
            headerNavSubs.style.boxShadow = `0px 0px 6px #ffffff`;
            cur.current += 1;
          }
          break;
        case "ArrowUp":
          break;
        case "ArrowDown":
          if (cur.current === -1 || cur.current === -2) {
            let headerNav = cur.current === -1 ? document.querySelectorAll('[head-selectable]')[0] : document.querySelectorAll('[head-selectable]')[1];
            if (headerNav) {
              headerNav.style.removeProperty("box-shadow");
              headerNav.style.removeProperty("outline");
            };
            cur.current = 0;
            document.querySelectorAll('li')[0].classList.add('activeLanguage')

            return
          };
          break;
        case "Enter":
          if (cur.current == -1) {
            let headerNavSubs = document.querySelectorAll('[head-selectable]')[0].title;
            if (headerNavSubs) {
              navigate(e,`${headerNavSubs}`)
            }
            return;
          }
          else if (cur.current === -2) {
            let headerNavProfile = document.querySelectorAll('[head-selectable]')[1].title;
            if (headerNavProfile) {
              navigate(e,`${headerNavProfile}`)
            }
            return;
          }
          break;

        default:
          break;
      }
    }
  }

  const handleOk = (id) => {
    let selectedItem = document.getElementById(id)
    let state = {
      languageId: selectedItem.getAttribute('lang-id'),
      languageName:selectedItem.getAttribute('lang-name')
    }

    let path = `${selectedItem.getAttribute('to')}?languageId=${selectedItem.getAttribute('lang-id')}&languageName=${selectedItem.getAttribute('lang-name')}`;
    navigate({},path, state)
  }
  const handlHorizontal = (currentId, actionId) => {
    document.getElementById(`item_${currentId}`).classList.remove('activeLanguage')
    document.getElementById(`item_${actionId}`).classList.add('activeLanguage')
    document.getElementById(`item_${actionId}`).scrollIntoView(false)
    setSelectedItem(`item_${actionId}`)

  }

  return (
    <>
      {/* <Header /> */}
      <div className="allLanguageWrap">
        <h2>All Languages</h2>
        {languages.length ? (
          <ul className="allLanguageList">
            {languages.map((lang, id) => (
              <li to={`/language-detail`} id={`item_${id}`} lang-name={lang.language?.name || ""} lang-id={lang?.language?._id || '5f510b5fc5916e1534b5cd35'} className="languageSLideBox" li-selectable='languages'>
                <h2>{lang.language?.icon_text || ""}</h2>
                <p>{lang.language?.name || ""}</p>
              </li>
            ))}
          </ul>
        ) : null}

      </div>
      {loading ? <div className="loaderPlan"><Loader /></div> : null}

      {/* <Footer /> */}

      <style jsx="true">{`
        .allLanguageWrap {                  
          background: #270048;
          width: 100%;
          height: calc(100vh - 35px);
          padding: 40px 8px 80px 8px;
          box-sizing: border-box;
          overflow: scroll;
          padding-left:7px;
        }
        .allLanguageWrap {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .allLanguageWrap::-webkit-scrollbar {
          display: none;
        }

        .allLanguageWrap h2 {
          margin: 0;
          color: #fff;
          font-size: 15px;
          margin-bottom: 10px;
          font-weight: 600;
        }
        .allLanguageList {
          padding: 0;
          list-style-type: none;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
        }
        .languageSLideBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #582484;
          border-radius: 3px;
          text-align: center;
          width: 64px;
          padding: 5px 0;
          margin-right: 10px;
          margin-bottom: 10px;
        }
        .languageSLideBox.activeLanguage {
          border: 1px solid #29f87e;
        }
        .languageSLideBox h2 {
          margin: 0;
          font-size: 18px;
          line-height: 9px;
          color: #ffffff;
          font-weight: 700;
          background-color: #270048;
          border-radius: 100%;
          width: 33px;
          height: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .languageSLideBox.activeLanguage h2 {
          background-color: #29f87e;
          color: #270048;
        }
        .languageSLideBox p {
          margin: 0;
          font-size: 10px;
          line-height: 22px;
          color: #ffffff;
          font-weight: 300;
          padding-top: 0;
        }
        .languageSLideBox.activeLanguage p {
          color: #29f87e;
          font-weight: 700;
        }
      `}</style>
    </>
  );
};

export default AllLanguage;
