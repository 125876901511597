import UserProvider from "./Contexts/userContext";
import { routes } from "./routes";
import { root } from "./index";
import { querySplitter } from "./utils/helper";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import App from "./App";
const localArray = []

export const navigate = (e, pathname, state = {}, inserted = false) => {
  // console.log("even", e, inserted)
  const newState = querySplitter(pathname);
  // if ctrl or meta key are held on click, allow default behavior of opening link in new tab
  if (e.metaKey || e.ctrlKey) {
    return;
  }

  // start custom routing changes
  let logged_in = Cookies.get('logged_in')
  if (pathname == '/login' && logged_in == 'true') {
    pathname = '/'
  }
  ReactGA.send({ hitType: "pageview", page: pathname, title: "Custom Title" });

  if (localStorage.getItem('visitedRoutes')) {
    const visitedRoutesArray = JSON.parse(localStorage.getItem('visitedRoutes'));
    if (pathname === '/menu' && visitedRoutesArray.includes('/menu')) {
    }
    else {
      if (!inserted) {
        visitedRoutesArray.push(pathname);

      }
    }
    localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoutesArray));
  }
  else {
    localStorage.setItem('visitedRoutes', JSON.stringify(localArray));
    const visitedRoutesArray = JSON.parse(localStorage.getItem('visitedRoutes'));
    if (!inserted) {
      visitedRoutesArray.push(pathname);
    }
    localStorage.setItem('visitedRoutes', JSON.stringify(visitedRoutesArray));
  }

  let routePath = pathname;
  console.log("navigation", pathname)
  if (pathname === '/menu' || pathname === "menu") {
    Cookies.remove('pageNo');
    Cookies.remove('page');
  }
  if (pathname === '/' || pathname === "") {
    let newlocalArray = [];
    newlocalArray.push('/')
    localStorage.setItem('visitedRoutes', JSON.stringify(newlocalArray));
  }
  if (pathname?.includes('?')) {
    routePath = pathname.split('?')[0]
  }
  console.log("on navigating routes are stored", JSON.parse(localStorage.getItem('visitedRoutes')))
  window.history.pushState(state = newState, routePath, window.location.origin + routePath);

  // prevent full page reload
  if (!Object.keys(e).length === 0) {
    e.preventDefault();
  }
  // update url
  if (routePath == '/player') {
    root.render(<UserProvider>{routes[routePath]}</UserProvider>);
  }
  else {
    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }

}




export const navigateBack = () => {
  let allVisitedRoutes = JSON.parse(localStorage.getItem('visitedRoutes'));
  // console.log("on back from player", allVisitedRoutes[allVisitedRoutes.length - 1])
  let lastRoute = allVisitedRoutes[allVisitedRoutes.length - 1];
  let state = {};
  let stateData = {};
  if (allVisitedRoutes?.length > 1) {
    // this method needs to be optimised 
    let PrevRoute = allVisitedRoutes[allVisitedRoutes.length - 2];
    if (PrevRoute.includes('?')) {
      stateData = querySplitter(PrevRoute);
      let prevRoute = PrevRoute.split('?')[0]
      // state = {
      //   ...stateData
      // }
    }

    // do not navigate back to menu page .
    if (PrevRoute === '/menu' || PrevRoute === "menu") {
      allVisitedRoutes.pop()
      PrevRoute = allVisitedRoutes[allVisitedRoutes.length - 2];
    }
    allVisitedRoutes.pop();
    if (PrevRoute === '/' || PrevRoute === "") {
      let newlocalArray = [];
      newlocalArray.push('/')
      localStorage.setItem('visitedRoutes', JSON.stringify(newlocalArray));
    }
    window.history.replaceState(stateData, '', PrevRoute) // updating the  history with current URL.
    localStorage.setItem('visitedRoutes', JSON.stringify(allVisitedRoutes));
    if (PrevRoute.includes('?')) {
      PrevRoute = PrevRoute.split('?')?.[0];
    }
    console.log("navigating back from player screen", PrevRoute, lastRoute)
    if (lastRoute.includes('?') && lastRoute.split('?')?.[0] == '/player') {
      root.render(<UserProvider><App /></UserProvider>);

    }
    // render the route


    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }
  else if (allVisitedRoutes?.length == 1) {
    let PrevRoute = allVisitedRoutes[allVisitedRoutes.length - 1];
    window.history.replaceState(stateData, '', PrevRoute) // updating the  history with current URL.

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }
}
