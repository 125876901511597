

const apiUrl = process.env.REACT_APP_JIOAPI_BASE_URL+"/api";//"https://stg-jioapi.ottplay.com/api";
const subsApi = process.env.REACT_APP_WEBAPI_BASE_URL+"/";//"https://subs-api.ottplay.com/";
const defaultImgUrl = "https://images.ottplay.com/static/poster_default.png"
const SECRET_KEY = "t^Ib88R;J|>':+MDH_NMShyXeX*t[ol8Pt(FV)O${:E<+sujfAVYc(aR9<bmc)q"


console.log("process.env ",process.env)

const SITE_CONFIG = {
    apiUrl,
    subsApi,
    SECRET_KEY,
    defaultImgUrl
    }


module.exports = SITE_CONFIG